import {useEffect, useState} from "react";
import Web3Modal from "web3modal";
import providerOptions from "../utils/providerOptions";
import {ethers} from "ethers";

const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions // required
});

const useWeb3Modal = () => {
    const [library, setLibrary] = useState();
    const [account, setAccount] = useState();
    const [chainId, setChainId] = useState();
    const [network, setNetwork] = useState();
    const [verified, setVerified] = useState();
    const [provider, setProvider] = useState();
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [signature, setSignature] = useState("");
    const [signedMessage, setSignedMessage] = useState("");


    const connectWallet = async () => {
        try {
            const provider = await web3Modal.connect();
            const library = new ethers.providers.Web3Provider(provider);
            const accounts = await library.listAccounts();
            const network = await library.getNetwork();
            setProvider(provider);
            setLibrary(library);
            if (accounts) setAccount(accounts[0]);
            setChainId(network.chainId);
        } catch (error) {
            setError(error);
        }
    };

    const signMessage = async () => {
        if (!library) return;
        try {
            const signature = await library.provider.request({
                method: "personal_sign",
                params: [message, account]
            });
            setSignedMessage(message);
            setSignature(signature);
        } catch (error) {
            setError(error);
        }
    };

    const refreshState = () => {
        setAccount();
        setChainId();
        setNetwork("");
        setMessage("");
        setSignature("");
        setVerified(undefined);
    };

    const disconnect = async () => {
        await web3Modal.clearCachedProvider();
        refreshState();
    };

    useEffect(() => {
        (async () => {
            if (web3Modal.cachedProvider) {
                await connectWallet();
            }
        })()
    }, []);

    useEffect(() => {

        if (provider?.on) {
            const handleAccountsChanged = async (accounts) => {
                if(accounts.length === 0) {
                    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
                    window.location.reload()
                }
                console.log("accountsChanged", accounts);
                if (accounts) setAccount(accounts[0]);
            };

            const handleChainChanged = (_hexChainId) => {
                console.log(_hexChainId);
                setChainId(_hexChainId);
            };

            const handleDisconnect = async () => {
                console.log("disconnect", error);
                await disconnect();
            };

            provider.on("accountsChanged", handleAccountsChanged);
            provider.on("chainChanged", handleChainChanged);
            provider.on("disconnect", handleDisconnect);

            return () => {
                if (provider.removeListener) {

                    provider.removeListener("accountsChanged", handleAccountsChanged);
                    provider.removeListener("chainChanged", handleChainChanged);
                    provider.removeListener("disconnect", handleDisconnect);
                }
            };
        }
    }, [provider]);

    return {library, account, chainId, network, verified, provider, error, connectWallet, signature, signMessage, signedMessage, disconnect}
}

export default useWeb3Modal