import { Col, Container, Row } from "react-bootstrap";
import about from "../assets/images/about.png";
import aboutVideo from "../assets/images/about-video.mp4";
import about_Video from "../assets/images/about_video.mp4";
import DownArrow from "./DownArrow";

const About = () => {
  return (
    <section className="section2">
      <Row className="mx-0 gap-4 gap-lg-0">
        <Col lg={6} className="ps-0">
          <div>
            <div className="about mx-3 ms-md-5 mt-3">
              <h2 className="section-title">About</h2>

              <p>
                Celestial Angels are 4,900 Angel NFTs, watching over the lives
                of their holders as ERC721 tokens on the Ethereum blockchain.{" "}
                <p className="mt-3">
                  These 4,900 one-of-a-kind art pieces are distributed through 2
                  phases; each representing the two sides of the constant battle
                  between the Guardians (2,450 Guardian Angels) and those who
                  have already Fallen (2,450 Fallen Angels).
                </p>
                <p className="mt-3">
                  Every angel, whether Guardian or Fallen, will be represented
                  by a pixel in a high-definition photo-mosaic artwork, which
                  will only be revealed once both phases have been completed.
                </p>
                <p className="mt-3">
                  After its official unveiling, this photo-mosaic artwork
                  consisting of 4,900 Angelic pixels will be converted into a
                  1/1 NFT and will be listed for auction as part of a final
                  third collection.
                </p>
                <p className="mt-3">
                  The proceeds from this auction will then be distributed back
                  to holders with complete sets based on a given formula.
                </p>
                <br />
                (View part 3 of roadmap for more details)
              </p>
              <a
                href="https://discord.com/invite/ZgcZNwgQCJ"
                target="_blank"
                className="btn btn-primary btn-wallet join-discord-btn"
              >
                <i class="fa-brands fa-discord"></i>
                <span>JOIN OUR DISCORD</span>
              </a>
            </div>
          </div>
        </Col>

        <Col lg={6} className="pe-0 align-self-center">
          {/* <img src={about} /> */}
          <video
            className="mt-5 w-100"
            src={about_Video}
            loop={true}
            autoPlay={true}
            muted={true}
            controls={false}
            playsInline
          ></video>
        </Col>
      </Row>
    </section>
  );
};

export default About;
