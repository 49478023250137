import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../../utils/connectors"
import { connectWallet } from "../../utils/connectWallet";
import Header from "../../components/Header"
import loader from "../../assets/images/loader.gif"
import { Col, Container, Row, Accordion } from "react-bootstrap";
//import xlsx from 'node-xlsx'
import * as XLSX from "xlsx";
import readXlsxFile from 'read-excel-file'
import {toChecksumAddress} from 'ethereum-checksum-address'


import { nFTCrowdsale_addr, nFT1_addr, nFT2_addr } from "../../contract/addresses";
import NFTCrowdsaleAbi from "../../contract/NFTCrowdsale.json"
import NFTFallen from "../../contract/FallenNFT.json"
import NFTGuardian from "../../contract/GuardianNFT.json"
import { ethers, BigNumber } from 'ethers';
import Web3Modal from 'web3modal';

function Whitelist() {

    const [loaded, setLoaded] = useState(false)
    const [addresses, setAddresses] = useState()

    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal,
        active: networkActive, error: networkError, activate: activateNetwork
    } = useWeb3React();

    const loadProvider = async () => {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            return provider.getSigner();
        } catch (e) {
            console.log("loadProvider default: ", e);
        }
    };

    const [owner , setOwner] =useState(false)
    const callOwner = async ()=>{
      try {
        let signer = await loadProvider();
        let NFTCrowdsaleContract = new ethers.Contract(
          nFTCrowdsale_addr,
          NFTCrowdsaleAbi,
          signer
        );
        let _owner = await NFTCrowdsaleContract.owner()
        console.log("_owner",_owner)
        if(_owner == account){
          console.log("_owner",true)
          setOwner(true)
        }else{
          setOwner(false)
        }
      } catch (error) {
        console.log("_owner",error)
      }
    }
  
    useEffect(
      async()=>{
        if(account){
          await callOwner()
        }
        console.log(account)
      }
      ,[account])


  
    
    useEffect(() => {
        injectedConnector
            .isAuthorized()
            .then((isAuthorized) => {
                setLoaded(true)
                if (isAuthorized && !networkActive && !networkError) {
                    activateNetwork(injectedConnector)
                }
            })
            .catch(() => {
                setLoaded(true)
            })
    }, [activateNetwork, networkActive, networkError])

    var myVar;

    function myFunction() {
        myVar = setTimeout(showPage, 3000);
    }

    function showPage() {
        document.getElementById("loader").style.display = "none";
        document.getElementById("myDiv").style.display = "block";
    }

    const formData = new FormData();
      
    const handleChangeImage = e => {
        let _address = []
        if(e.target.files[0]){
            
            let temp = []
            readXlsxFile(e.target.files[0]).then((rows) => {
                rows.forEach(element => {
                    console.log("add",element)

                    let address = toChecksumAddress(element[0])
                    _address.push(address)
                });
              })
           
        }
        console.log("add",_address)
        setAddresses(_address)

     }

     const upload = async () =>{
         try {
            let signer = await loadProvider()
            let NFTCrowdsaleContract = new ethers.Contract(nFTCrowdsale_addr, NFTCrowdsaleAbi, signer)
            console.log(addresses)
            let tx = await NFTCrowdsaleContract.addWhitelist(addresses)
            await tx.wait()
         } catch (error) {
             console.log("whitelist",error)
         }
     }



    myFunction();
    return (

        <>
{owner ?  <>
            <div id="loader">
                <img src={loader} />
            </div>

            <div style={{ display: "none" }} id="myDiv" class="animate-bottom">

                <Header />

                <section className="section w-100 ">
                    <Container>

                        {/* <div className="head-btn">
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">SetNftaddress</button>
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Start Auction</button>
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Bid</button>
                    </div> */}

                        <Row >
                            <Col lg={4} className="center-box">
                                <div className="admin-page">
                                    <h3 className="admin-title">Whitelist</h3>
                                    <div className="form-group">
                                        <label for="datetime">Upload excel file</label>
                                        <input className="form-control" type="file" placeholder="Enter ID to get NFT" onChange={(e)=>handleChangeImage(e)} />
                                    </div>
                                    <div className="admin-btn-group">
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3" onClick={upload}>Submit</button>
                                    </div>

                                </div>
                            </Col>
                          
                        </Row>


                    </Container>
                </section>

                {/* <div id="footer-section" className="position-relative">
                    <Footer />
                </div> */}

            </div>
            </>:null}
        </>

    )
}

export default Whitelist;