import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../utils/connectors";
import { connectWallet } from "../utils/connectWallet";
import Logo from "../assets/images/logo 2.png";
import opensea from "../assets/images/opensea.svg";
import Header from "../components/Header";
import Team from "../components/Team";
import FAQs from "../components/FAQs";
import DownArrow from "../components/DownArrow";
import JoinUs from "../components/JoinUs";
import DrawingConcept from "../components/DrawingConcept";
import About from "../components/About";
import Roadmap from "../components/Roadmap";
import Footer from "../components/Footer";
import Collection from "../components/Collection";
import loader from "../assets/images/loader.gif";
import Subtraction from "../assets/images/subtraction.png";
import Addition from "../assets/images/addition.png";
import MintButton from "../assets/images/mint-btn.png";
import congrates from "../assets/images/congrats.png";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import Timer from "../components/Timer";
import { useParams } from "react-router-dom";

import { nFTCrowdsale_addr, nFT1_addr, nFT2_addr } from "../contract/addresses";
import NFTCrowdsaleAbi from "../contract/NFTCrowdsale.json";
import { ethers, BigNumber } from "ethers";
import Web3Modal from "web3modal";
import useWeb3Modal from "../hooks/useWeb3Modal";

function PrivateSale(props) {
  const [loaded, setLoaded] = useState(false);
  const id  = props.id;
  const [white, setWhite] = useState(
    "Oops your not Whitelisted please come back on xx/xx/xxxx for public mint day"
  );
  const [err, setErr] = useState(null);
  const [value, setValue] = useState('0');
  const [showImage, setShowImage] = useState(false);
  const {
    connector,
    library,
    activate,
    deactivate,
    active,
    errorWeb3Modal,
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React();

  const loadProvider = async () => {
    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      return provider.getSigner();
    } catch (e) {
      console.log("loadProvider default: ", e);
    }
  };

  /*useEffect(() => {
    console.log("pageID", id);
    injectedConnector
      .isAuthorized()
      .then((isAuthorized) => {
        setLoaded(true);
        if (isAuthorized && !networkActive && !networkError) {
          activateNetwork(injectedConnector);
        }
      })
      .catch(() => {
        setLoaded(true);
      });
  }, [activateNetwork, networkActive, networkError, id]);*/


  const {connectWallet, account, chainId} = useWeb3Modal()

  useEffect(async () => {
    if (account) {
      await iswhitelist();
    }
  }, [account, chainId, id]);

  const nftType = (id) => {
    if (Number(id) > 1) {
      return 1;
    } else {
      return 2;
    }
  };
  const nftTypeApi = (id) => {
    if (id > 1) {
      return 0;
    } else {
      return 1;
    }
  };
  const buy = async () => {
    try {
      let signer = await loadProvider();
      let NFTCrowdsaleContract = new ethers.Contract(
        nFTCrowdsale_addr,
        NFTCrowdsaleAbi,
        signer
      );
      let _value = await NFTCrowdsaleContract.Sale_price(id);
      setValue(ethers.utils.formatEther(_value.toString()))
      console.log("nftType(id)", nftType(id));
      let tx = await NFTCrowdsaleContract.buyNFT(nftType(id), id, {
        value: _value,
      });
      let transaction = await tx.wait();
      if(transaction.confirmations > 0){
        setShowImage(true)
      }
      console.log("tx",tx)
    } catch (error) {
      console.log("nftType(id)", nftType(id));
      console.log(error)
      setErr(error.error.message);
    }
  };

  const iswhitelist = async () => {
    let signer = await loadProvider();
    let NFTCrowdsaleContract = new ethers.Contract(
      nFTCrowdsale_addr,
      NFTCrowdsaleAbi,
      signer
    );
    let _value = await NFTCrowdsaleContract.Sale_price(id);
    setValue(ethers.utils.formatEther(_value.toString()))
    let tx = await NFTCrowdsaleContract._whitelist(account);
    console.log("white", tx);
    if (tx){
      console.log("white", tx);
      setWhite(
        "Congratulations, you are whitelisted. You may now start minting."
      );
    }else {
      setWhite("Oops your not Whitelisted please come back on xx/xx/xxxx for public mint day")
    }
      
  };


  return (
    <>
    

      <div >
        <section className="section-mint mint-100-vh">
          <div className="">
            {/* 
                            <div className="main-title col-xl-5 col-lg-7 col-md-9 col-12">

                                <div className="opensea justify-content-center flex-column flex-md-row">


                                    <a href="https://opensea.io/" target="_blank" className="primary-btn custom-btn image-btn">
                                        <img src={opensea} /><span className="">OpenSea</span>
                                    </a>
                                </div>


                            </div> */}

            <div class="work-arrow mint-arrow-section">
              <div className="mint-box">
                <Timer nft_type={nftTypeApi(id)} sale_type={1} />
                {!showImage ? 
                <div className="mint-body">
                  {account ? (
                    <>
                      <h4>{white}</h4>
                      <div className="mint-qty">
                        <div className="position-relative">
                          <img src={Subtraction} className="minus" />
                          <input
                            className="qty"
                            readOnly
                            type="text"
                            value="1"
                          />
                          <img src={Addition} className="plus" />
                        </div>
                      </div>
                      <h3>Total : {value} ETH</h3>
                      <img
                        src={MintButton}
                        className="mint-btn"
                        onClick={buy}
                      />
                      <p style={{ color: "red" }}>{err}</p>
                    </>
                  ) : (
                    <>
                      <h3>MINTING IS LIVE...</h3>
                      <h4>Connect your wallet to start minting</h4>
                    </>
                  )}
                </div> : <div className="mint-body">
                {account ? (
                  <>
                  
                    <div className="mint-qty">
                      <div className="position-relative congrats-img">
                        <img src={congrates} className="minus "  />
                      
                      </div>
                    </div>
                    <h3>You have now minted your NFT and it will be in your wallet soon, please be patient.</h3>
                    
                  </>
                ) : (
                  <>
                    <h3>MINTING IS LIVE...</h3>
                    <h4>Connect your wallet to start minting</h4>
                  </>
                )}
              </div>}
                <div className="mint-footer">
                  {account ? (
                    <div className="group-btn-flex">
                      <button className="btn btn-primary btn-wallet">
                        Wallect Connected
                      </button>
                      <button className="btn btn-primary btn-wallet mt-1 mb-1">
                        wallet address :{" "}
                        {account.substring(0, 4) +
                          "..." +
                          account.substring(4, 8)}
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={connectWallet}
                      className="btn btn-primary btn-wallet"
                    >
                      CONNECT YOUR WALLET
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div id="footer-section" className="position-relative">
                    <Footer />
                </div> */}
      </div>
    </>
  );
}

export default PrivateSale;
