import { Container } from "react-bootstrap";

const FAQs = (props) => {
  return (
    <section className="faqs-section ">
      <Container className="h-100">
        <div className="faqs col-md-8">
          <h1>FAQs</h1>
          <h3 className="mt-5 mb-5 font-30">
            FREQUENTLY <br className="line-for-d-none" /> ASKED{" "}
            <br className="line-for-d-none" /> QUESTIONS
          </h3>
          <p className="text-justify">
            <strong> What is an NFT?</strong> <br /> A non-fungible token is a
            non-interchangeable unit of data stored on a blockchain, a form of
            digital ledger. Types of NFT data units include digital files such
            as photos, videos, and audio. <br />
            <strong>
              {" "}
              How to install the metamask extension on google chrome?
            </strong>{" "}
            <ol>
              <li>Go to the Metamask website.</li>
              <li>Click “Get Chrome Extension” to install Metamask.</li>
              <li> Click “Add to Chrome” in the upper right.</li>
              <li>Click “Add Extension” to complete the installation.</li>
              <li>
                Click on the Metamask logo in the upper right hand corner of
                your Google chrome browser.{" "}
              </li>
            </ol>
            <strong>What is a whitelist?</strong> <br /> A whitelist is a tool
            used for awarding a particular privilege to members for completing
            certain required tasks. In this case the reward is the possibility
            to mint an NFT. <br />
            <strong> What is Minting? </strong> <br />
            Minting an NFT is how your digital art becomes a part of the
            Ethereum blockchain–a public ledger that is unchangeable and
            tamper-proof. Similar to the way that metal coins are minted and
            added into circulation, NFTs are also tokens that get “minted” once
            they are created.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default FAQs;
