import axios from "axios";
import { useEffect, useState } from "react";
import Countdown from 'react-countdown';

function Timer(props)
{
    const [timer,setTimer] = useState([])
    const [d,setD] = useState(new Date(new Date().toUTCString()))

    const url = 'https://api.celestial.pluton.ltd/public/';
    useEffect(async ()=>{
        await axios.get(url+'timer-setting?nft_type='+props.nft_type+'&sale_type='+props.sale_type).then((res)=>{
            setTimer(res.data.data)
        })
      console.log('testtet')
    },[]);
    useEffect(async ()=>{
        setTimeout(()=>{
            setD(new Date(new Date().toGMTString()))
        },1000)
        console.log({d,c:new Date(timer.date_time).toUTCString()})
    },[d])
    const renderer = ({ days,hours, minutes, seconds, completed }) => {
        if (timer.timer_type == '0' || completed) {
            // Render a completed state
            return <div className="timer-box">
                    <div className="inner">
                        <span>00</span>
                    </div>
                    <div className="inner">
                        <span>00</span>

                    </div>
                    <div className="inner">
                        <span>00</span>

                    </div>
                </div>;

        } else {
            // Render a countdown
            return <div className="timer-box">
                {/* <div className="inner">
                        <span>{days}</span>
                    </div> */}
                    <div className="inner">
                        <span>{("0" + hours).slice(-2)}</span>
                    </div>
                    <div className="inner">
                        <span>{("0" + minutes).slice(-2)}</span>

                    </div>
                    <div className="inner">
                        <span>{("0" + seconds).slice(-2)}</span>
                    </div>
                </div>;
        }
        };

        return <>
         {
            timer.date_time ? <Countdown
            now={()=>{
                return d
            }}
            date={new Date(timer.date_time)}
            intervalDelay={1000}
            renderer={renderer}
        />:''
        }
        </>
}

export default Timer