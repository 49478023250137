import { Row, Col, Container, Button } from "react-bootstrap";
import concept_1 from "../assets/images/concept-1.png";
import angelone from "../assets/images/mobile-angel-one.png";
import angeltwo from "../assets/images/mobile-angel-two.png";

const DrawingConcept = (props) => {
  return (
    <section className="drawing-concept-section">
      <Container className="">
        <div className="drawing-concept">
          <h1 className="text-center mb-2 mb-md-5 section-title">
            DRAWING CONCEPT
          </h1>

          {/* Images will be placed here. */}
          {/* <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                    </Row> */}
          {!props.mobile ? (
            <img src={concept_1} />
          ) : (
            <img src={angelone} className="w-100" />
          )}

          <div className="box">
            <p className="description">
              <p className="mt-3">
                The inspiration behind the art of Celestial Angels comes
                primarily from the region where I grew up in. A region shaped by
                tales of Kingdoms, legends, Fairy Tales, and mythologies. Hence,
                it was of my utmost priority to utilize this opportunity and
                demonstrate this inspiration through the artworks of Celestial
                Angels.{" "}
              </p>
              <p className="mt-3">
                The graphical design used to create these 4,900 one-of-a-kind
                artworks also heavily relies on the inspiration from early
                Roman/Mesopotamian/Greek arts and architecture. In order to
                produce a collection with a diversity of traits and subtleties,
                modern anime art has also been integrated into the composition
                of each artwork. On the other hand, the implementation of ideas
                from well-known characters in various comic books can also be
                seen through the pieces, which are mere depictions of old tales
                rendered with modern techniques.{" "}
              </p>
              <p className="mt-3">
                Each of these angels brings a unique narrative for its holder,
                which can be observed through their various traits such as;
                clothing, hairstyle, facial features, and many more. The goal
                was to create a world of characters that are, by all means,
                Celestial, brought to the virtual realm from my imaginations
                through pen, paper, and tablet.
              </p>
              <p className="mt-3">
                I hope you enjoy our world and welcome to the virtual realm of
                Celestial Angels. <br /> Godyar
              </p>
            </p>
            {props.mobile ? <img src={angeltwo} className="w-100" /> : ""}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default DrawingConcept;
