import { Row, Col, Container, Button } from "react-bootstrap";
import discordTransparent from "../assets/images/discord-transparent.png";
import join from "../assets/images/join.png";
import joinVideo from "../assets/images/join-video.mp4";

const JoinUs = (props) => {
  return (
    <section className="joinus-section">
      <div className="joinus pb-5 pb-md-0">
        <Row className="mx-0 align-items-center">
          <Col lg={6} md={12} className="px-0 mobile-order-2">
            {/* <img src={joinVideo} /> */}
            <video
              className="w-100 h-100"
              src={joinVideo}
              loop={true}
              autoPlay={true}
              muted={true}
              controls={false}
              playsInline
            ></video>
          </Col>
          <Col
            lg={6}
            sm={12}
            md={12}
            className="faqs join-text mobile-order-1 mt-4"
          >
            <h1 className=" mb-5">NEWS</h1>
            <div className="d-flex align-items-center justify-content-start ml-3 h-100">
              <div className="box col-12 col-lg-8 ml-3">
                <p className="description text">
                  Please note that all updates in regards of upcoming events,
                  important dates, drops and many more will be released within
                  our official socials (Instagram|Twitter|Discord). However; we
                  recommend you to stay active on our discord as you will be
                  able to ask any further questions or concerns you may have
                  from our helpful admins whom will be delighted to assist you.
                </p>
                <div className="text-center">
                  <a
                    href="https://discord.com/invite/ZgcZNwgQCJ"
                    target="_blank"
                    className="text-white"
                  >
                    {" "}
                    <Button className="mt-3 w-100">
                      <img
                        width={24}
                        src={discordTransparent}
                        className="me-2 mb-1"
                      />
                      <span>JOIN OUR DISCORD</span>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default JoinUs;
