import logo from "./logo.svg";
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/fontawesome/css/all.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import MyNfts from "./components/MyNfts";
import PublicSale from "./screens/PublicSale";
import PrivateSale from "./screens/PrivateSale";
import Admin from "./screens/admin/Admin";
import TimerSetting from "./screens/admin/TimerSetting";
import Whitelist from "./screens/admin/Whitelist";
import Reveal from "./screens/admin/Reveal";
import Sale from "./screens/admin/Sale";
import ProtectedRoute from "./components/ProtectedRoute";
import { nFTCrowdsale_addr, nFT1_addr, nFT2_addr } from "./contract/addresses";
import NFTCrowdsaleAbi from "./contract/NFTCrowdsale.json";
import { ethers, BigNumber } from "ethers";
import Web3Modal from "web3modal";
import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3Modal from "./hooks/useWeb3Modal"

function App() {

  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/public-sale/:id" element={<PublicSale />} />
        <Route path="/private-sale/:id" element={<PrivateSale />} />
        <Route path="/nft" element={<MyNfts />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/timer-setting" element={<TimerSetting />} />
          <Route path="/admin/whitelist" element={<Whitelist />} />
          <Route path="/admin/reveal" element={<Reveal />} />
          <Route path="/admin/Sale" element={<Sale />} />
      </Routes>
    </Router>
  );
}

export default App;
