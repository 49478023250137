import React, { useEffect, useState } from "react";
import collection_heading from "../assets/images/Collection_heading.png";
import celestialAngels from "../assets/images/celestial-angels.png";
import collection from "../assets/images/collection.png";
import mobilecollection1 from "../assets/images/mobile-collection1.png";
import mobilecollection2 from "../assets/images/mobile-collection2.png";
import mobilecollection3 from "../assets/images/mobile-collection3.png";
import mobilecollection4 from "../assets/images/mobile-collection4.png";
import mobilecollection5 from "../assets/images/mobile-collection5.png";
import mobilecollection6 from "../assets/images/mobile-collection6.png";
import collectionSlider1 from "../assets/images/collection-slider-1.png";
import collectionSlider2 from "../assets/images/collection-slider-2.png";
import opensea from "../assets/images/opensea.svg";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../utils/connectors";
import { connectWallet } from "../utils/connectWallet";

const Collection = ({ connectWallet, account, disconnect }) => {
  const [loaded, setLoaded] = useState(false);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    centerMode: true,
    centerPadding: "30px",
    infinite: true,
    pauseOnHover: false,
    arrows: false,
    dots: false,
    cssEase: "linear",
  };
  const mobile_settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 8000,
    centerMode: true,
    centerPadding: "30px",
    infinite: true,
    pauseOnHover: false,
    arrows: false,
    dots: false,
    cssEase: "linear",
  };
  return (
    <section className="collection-section">
      <div>
        <div className="grid-parent">
          <div className="grid-child hide-mobile">
            <Slider {...settings}>
              <div>
                <img src={collectionSlider1} />
              </div>
              <div>
                <img src={collectionSlider2} />
              </div>
            </Slider>
          </div>
          <div className="grid-child z-index-plus-1 align-self-center text-center">
            <Slider {...mobile_settings}>
              <div>
                <img src={mobilecollection1} className="hide-pc w-100" />
              </div>
              <div>
                <img src={mobilecollection2} className="hide-pc w-100" />
              </div>
              <div>
                <img src={mobilecollection3} className="hide-pc w-100" />
              </div>
              <div>
                <img src={mobilecollection4} className="hide-pc w-100" />
              </div>
              <div>
                <img src={mobilecollection5} className="hide-pc w-100" />
              </div>
              <div>
                <img src={mobilecollection6} className="hide-pc w-100" />
              </div>
            </Slider>
            <div className="collection text-center">
              {/* <img src={collection_heading} /> */}
              <div>
                <h1>COLLECTIONS</h1>
                {/* <img className="celestial-angels" src={celestialAngels} style={{border:"1px solid red"}} /> */}
              </div>
              {account ? (
                <button
                  style={{ padding: "20px 20px 20px 20px" }}
                  className="btn btn-primary btn-wallet p-3 mb-0 mb-md-3"
                  onClick={disconnect}
                >
                  CONNECTED
                </button>
              ) : (
                <button
                  style={{ padding: "20px 20px 20px 20px" }}
                  onClick={connectWallet}
                  className="btn btn-primary btn-wallet mb-0 mb-md-3"
                >
                  CONNECT YOUR WALLET
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collection;
