import React, { useEffect, useState } from "react";
import { Col, Container, Row, Accordion } from "react-bootstrap";
import Logo from "../assets/images/logo 2.png";
import opensea from "../assets/images/opensea.svg";
import Header from "../components/Header";
import btnPic from "../assets/images/background-road-map-1.png";
import btnImg from "../assets/images/btnimage.png";
import Team from "../components/Team";
import FAQs from "../components/FAQs";
import DownArrow from "../components/DownArrow";
import JoinUs from "../components/JoinUs";
import DrawingConcept from "../components/DrawingConcept";
import About from "../components/About";
import Roadmap from "../components/Roadmap";
import Footer from "../components/Footer";
import Collection from "../components/Collection";
import loader from "../assets/images/loader.gif";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../utils/connectors";
import { connectWallet } from "../utils/connectWallet";
import axios from "axios";
import PrivateSale from "./PrivateSale";
import PublicSale from "./PublicSale";
import useWeb3Modal from "../hooks/useWeb3Modal";

function Home() {
  const [loaded, setLoaded] = useState(false);
  const [timer, setTimer] = useState([]);
  console.log(timer);
  const [id, setId] = useState(null);
  const url = "https://api.celestial.pluton.ltd/public/";
  
  const [buttonTxt , setButtonTxt] = useState("Submit");

  const {connectWallet, account} = useWeb3Modal()

  /*const {
    connector,
    library,
    account,
    chainId,
    activate,
    deactivate,
    active,
    errorWeb3Modal,
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React();*/

  /*useEffect(() => {
    injectedConnector
      .isAuthorized()
      .then((isAuthorized) => {
        setLoaded(true);
        if (isAuthorized && !networkActive && !networkError) {
          activateNetwork(injectedConnector);
        }
      })
      .catch(() => {
        setLoaded(true);
      });
  }, [activateNetwork, networkActive, networkError]);*/

  useEffect(async () => {
    await axios.get(url + "current-timer").then((res) => {
      var d1 = new Date();
      var d2 = new Date(res.data.data.date_time);
      if (d1 < d2) {
        if (res.data.data.nft_type == "0" && res.data.data.sale_type == "0") {
          setId(2);
        } else if (
          res.data.data.nft_type == "0" &&
          res.data.data.sale_type == "1"
        ) {
          setId(3);
        } else if (
          res.data.data.nft_type == "1" &&
          res.data.data.sale_type == "0"
        ) {
          setId(0);
        } else if (
          res.data.data.nft_type == "1" &&
          res.data.data.sale_type == "1"
        ) {
          setId(1);
        }
        setTimer(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    setTimeout(showPage, 3000);
  }, []);


  function showPage() {
    document.getElementById("loader").style.display = "none";
    document.getElementById("myDiv").style.display = "block";
  }

  return (
    <>
      <div id="loader">
        <img src={loader} />
      </div>

      <div style={{ display: "none" }} id="myDiv" class="animate-bottom">
        <Header />

        <section className="section1">
          <div className="h-100">
            <Container>
              {id != null ? (
                <Row>
                  <Col lg={6} md={6}>
                    <div className="main-title col-xl-12 col-lg-12 col-md-12 col-12">
                      <div className="d-flex align-items-center celestial-angels">
                        <img className="hero-logo" src={Logo} />
                        <div className="">
                          <p className="celestial">Celestial</p>
                          <p className="angels">Angels</p>
                        </div>
                      </div>

                      <div className="opensea   flex-md-row  ">
                        <p className="mobile-hide">
                          Inspired by ancient Roman/Greek arts and
                          architecture, Celestial Angels are mere fabrications
                          from the minds of Nitefury, Naham & Jameson; brought
                          to the virtual realm by the talented artifex Godyar.
                        </p>

                        <a
                          href="https://opensea.io/"
                          // id="btnPic"
                          target="_blank"
                          className="primary-btn custom-btn image-btn"
                          id="hh"
                        >
                          <img src={opensea} />
                          <span className="">OPENSEA</span>
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    {timer.sale_type == "0" ? (
                      <PublicSale id={id} sale_type={timer.sale_type} />
                    ) : (
                      <PrivateSale id={id} sale_type={timer.sale_type} />
                    )}
                  </Col>
                </Row>
              ) : (
                <div className="main-title col-xl-5 col-lg-7 col-md-9 col-12">
                  <div className="d-flex align-items-center celestial-angels">
                    <img className="hero-logo" src={Logo} />
                    <div className="">
                      <p className="celestial">Celestial</p>
                      <p className="angels">Angels</p>
                    </div>
                  </div>

                  <div className="opensea  flex-md-row">
                    <p>
                      Inspired by ancient Roman/Greek arts and architecture,
                      Celestial Angels are mere fabrications from the minds of
                      Nitefury, Naham & Jameson; brought to the virtual realm by
                      the talented artifex Godyar.
                    </p>

                    <a
                      href="https://opensea.io/"
                      // id="btnPic"
                      target="_blank"
                      className="primary-btn custom-btn image-btn"
                      id="hh"
                    >
                      <img src={opensea} />
                      <span className="">OPENSEA</span>
                    </a>
                  </div>
                </div>
              )}
            </Container>
          </div>

          <div class="banner-arrow work-arrow ">
            <div className="mb-5">
              {id != null ? (
                ""
              ) : account ? (
                <button className="btn btn-primary btn-wallet">
                  CONNECTED
                </button>
              ) : (
                <button
                  onClick={connectWallet}
                  className="btn btn-primary btn-wallet"
                >
                  CONNECT YOUR WALLET
                </button>
              )}

              {/* <button onClick={(e) => {
                                    connectWallet(activate, "Error");
                                    e.preventDefault()
                                }} className="btn btn-primary btn-wallet">CONNECT YOUR WALLET</button> */}
            </div>

            <a href="#about-section">
              SCROLL TO DISCOVER
              <div class="down-arrow">
                <div class="chevron"></div>
                <div class="chevron"></div>
                <div class="chevron"></div>
              </div>
            </a>
          </div>
        </section>

        <div id="about-section" className="position-relative pb-5">
          <About />
          <DownArrow to="roadmap-section" />
        </div>

        <div id="roadmap-section" className="position-relative pt-5 ">
          <Roadmap />
        </div>

        <div id="team-section">
          <section className="girl-section-container pt-5 py-5 ">
            <div className="mt-2 position-relative">
              <Team />
              <DownArrow to="collection-section" />
            </div>
          </section>
        </div>
        <div className="position-relative">

        <div id="collection-section" >
          <Collection account={account} connectWallet={connectWallet} />
        </div>
          <DownArrow to="faqs-section" />
        </div>

        <section className="girl-section-container pt-0">
          <div id="faqs-section" className="position-relative">
            <FAQs />
            <DownArrow to="joinus-section" />
          </div>
          <div id="joinus-section" className="position-relative">
            <JoinUs />
          </div>
        </section>

        <div id="footer-section" className="position-relative">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Home;
