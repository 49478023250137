import { Col, Container, Row } from "react-bootstrap";
import designer from "../assets/images/designer.png";
import marketingManager from "../assets/images/marketing-manager.jpg";
import administrativeBusiness from "../assets/images/administrative-business.jpg";
import softwareEngineer from "../assets/images/lead-designer.jpg";

function Team() {
  return (
    <>
      <section className="team-section pt-5">
        <Container className="h-100">
          <div className="team">
            <h2 className="section-title text-center mb-3 mb-md-5">Team</h2>

            <Row className="gap-lg-0">
              <Col lg={3} md={6} className="col-6">
                <div className="team-box">
                  <div className="position-relative">
                    <img src={designer} />

                    <h4 className="yellow mt-1">Lead Designer </h4>

                    <h3>Godyar </h3>
                  </div>

                  <ul className="team-social">
                    <li>
                      <a
                        href="https://twitter.com/godyaar?s=11&t=XvP6xrd8fRtO9uTDb7hWJQ"
                        target="_blank"
                      >
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/godyar/?igshid=YmMyMTA2M2Y%3D"
                        target="_blank"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col lg={3} md={6} className="col-6">
                <div className="team-box">
                  <div className="position-relative">
                    <img src={marketingManager} />

                    <h4 className="green mt-1">
                      Co-Founder, Managing Director
                    </h4>

                    <h3>Naham</h3>
                  </div>

                  <ul className="team-social">
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col lg={3} md={6} className="col-6">
                <div className="team-box">
                  <div className="position-relative">
                    <img src={administrativeBusiness} />

                    <h4 className="purple mt-1">
                      Co-Founder, Creative Director{" "}
                    </h4>

                    <h3>Nitefury</h3>
                  </div>

                  <ul className="team-social">
                    <li>
                      <a
                        href="https://twitter.com/nitefury_eth?s=11&t=XvP6xrd8fRtO9uTDb7hWJQ"
                        target="_blank"
                      >
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/nitefury.eth/?igshid=YmMyMTA2M2Y%3D"
                        target="_blank"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col lg={3} md={6} className="col-6">
                <div className="team-box">
                  <div className="position-relative">
                    <img src={softwareEngineer} />

                    <h4 className="blue mt-1">Lead Developer</h4>

                    <h3>Jameson </h3>
                  </div>

                  <ul className="team-social">
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Team;
