import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "../../utils/connectors"
import { connectWallet } from "../../utils/connectWallet";
import Header from "../../components/Header"
import loader from "../../assets/images/loader.gif"
import { Col, Container, Row, Accordion } from "react-bootstrap";


import { nFTCrowdsale_addr, nFT1_addr, nFT2_addr } from "../../contract/addresses";
import NFTCrowdsaleAbi from "../../contract/NFTCrowdsale.json"
import FallenAbi from "../../contract/FallenNFT.json"
import GuardianAbi from "../../contract/GuardianNFT.json"
import { ethers, BigNumber } from 'ethers';
import Web3Modal from 'web3modal';
const axios = require('axios');

function Admin() {

    const [loaded, setLoaded] = useState(false)
    const [addr, serAddr] = useState()
    const [addr2, setAddr2] = useState()
    const [user_address, setUser_address] = useState()
    const [token_ID, setToken_ID] = useState()
    const [nft_1, setNft_1] = useState()
    const [nft_2, setNft_2] = useState()
    const [count, setCount] = useState()
    const [iswhitelist, setiswhitelist] = useState(false);
    const [uri, setUri] = useState([])
    const [nft1, setNft1] = useState([])
    const [nft2, setNft2] = useState([])
   

    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal,
        active: networkActive, error: networkError, activate: activateNetwork
    } = useWeb3React();

    const loadProvider = async () => {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            return provider.getSigner();
        } catch (e) {
            console.log("loadProvider default: ", e);
        }
    };

    const [owner , setOwner] =useState(false)
  const callOwner = async ()=>{
    try {
      let signer = await loadProvider();
      let NFTCrowdsaleContract = new ethers.Contract(
        nFTCrowdsale_addr,
        NFTCrowdsaleAbi,
        signer
      );
      let _owner = await NFTCrowdsaleContract.owner()
      console.log("_owner",_owner)
      if(_owner == account){
        console.log("_owner",true)
        setOwner(true)
      }else{
        setOwner(false)
      }
    } catch (error) {
      console.log("_owner",error)
    }
  }

  useEffect(
    async()=>{
      if(account){
        await callOwner()
      }
      console.log(account)
    }
    ,[account])

    useEffect(() => {
        injectedConnector
            .isAuthorized()
            .then((isAuthorized) => {
                setLoaded(true)
                if (isAuthorized && !networkActive && !networkError) {
                    activateNetwork(injectedConnector)
                }
            })
            .catch(() => {
                setLoaded(true)
            })
    }, [activateNetwork, networkActive, networkError])

    var myVar;

    function myFunction() {
        myVar = setTimeout(showPage, 3000);
    }

    function showPage() {
        document.getElementById("loader").style.display = "none";
        document.getElementById("myDiv").style.display = "block";
    }

    const loadOneNFT1 = async () => {
        try {

            let signer = await loadProvider()
            let NFTCrowdsaleContract = new ethers.Contract(nFT1_addr, FallenAbi, signer);

            //let user_account = ethers.utils.getAddress(user_address.toString())
            
            //let balanceOf = await NFTCrowdsaleContract.balanceOf(user_address)
            let ownerOf = await NFTCrowdsaleContract.ownerOf(token_ID)
            console.log(ownerOf)
            // let balance = balanceOf.toNumber()
            
            let counter = 0
            let tokenURI = await NFTCrowdsaleContract.tokenURI(token_ID)
            
            
            let details 
            let id = token_ID
                 const response = axios.get(tokenURI)
                .then(function (response) {
                   details ={id : id , owner : ownerOf , data : response.data}
                    setNft_1(details)
                })
          
            
        } catch (e) {
            console.log("data", e)
        }
    }

    const loadOneNFT2 = async () => {
        try {

            let signer = await loadProvider()
            let NFTCrowdsaleContract = new ethers.Contract(nFT2_addr, GuardianAbi, signer);

            //let user_account = ethers.utils.getAddress(user_address.toString())
            
            //let balanceOf = await NFTCrowdsaleContract.balanceOf(user_address)
            let ownerOf = await NFTCrowdsaleContract.ownerOf(token_ID)
            console.log(ownerOf)
            // let balance = balanceOf.toNumber()
            
            let counter = 0
            let tokenURI = await NFTCrowdsaleContract.tokenURI(token_ID)
            
            
            let details 
            let id = token_ID
                 const response = axios.get(tokenURI)
                .then(function (response) {
                   details ={id : id , owner : ownerOf , data : response.data}
                    setNft_2(details)
                })
          
            
        } catch (e) {
            console.log("data", e)
        }
    }
    const loadNFTs = async () => {
        try {

            let signer = await loadProvider()
            let NFTCrowdsaleContract = new ethers.Contract(nFT1_addr, FallenAbi, signer);

            let user_account = ethers.utils.getAddress(user_address.toString())
            
            let balanceOf = await NFTCrowdsaleContract.balanceOf(user_address)
            
            let balance = balanceOf.toNumber()
            console.log("balance", balance)
            let arr = []
            let counter = 0
            for (var i = 0; i < balanceOf; i++) {
                let id = await NFTCrowdsaleContract.tokenOfOwnerByIndex(user_account, i);
                id = id.toString()
                let tokenURI = await NFTCrowdsaleContract.tokenURI(id)
                console.log("tokenURI", {id , tokenURI})
                arr.push({id , tokenURI})
            }
            let details = []
            arr.forEach(i => {
                 const response = axios.get(i.tokenURI)
                .then(function (response) {
                   details.push({id : i.id , data : response.data})
                    console.log("response",{id : i.id , data : response.data},i);
                   setNft1([...details])
                })
            });
            
        } catch (e) {
            console.log("data", e)
        }
    }

    const loadNFTs2 = async () => {
        try {

            let signer = await loadProvider()
            let NFTCrowdsaleContract = new ethers.Contract(nFT2_addr, GuardianAbi, signer);
            let user_account = ethers.utils.getAddress(user_address.toString())
            
            let balanceOf = await NFTCrowdsaleContract.balanceOf(user_address)
            
            let balance = balanceOf.toNumber()
            console.log("balance", balance)
            let arr = []
            let counter = 0
            for (var i = 0; i < balanceOf; i++) {
                let id = await NFTCrowdsaleContract.tokenOfOwnerByIndex(user_account, i);
                id = id.toString()
                let tokenURI = await NFTCrowdsaleContract.tokenURI(id)
                console.log("tokenURI", {id , tokenURI})
                arr.push({id , tokenURI})
            }
            let details = []
            arr.forEach(i => {
                 const response = axios.get(i.tokenURI)
                .then(function (response) {
                   details.push({id : i.id , data : response.data})
                    console.log("response",{id : i.id , data : response.data},i);
                   setNft2([...details])
                })
            });

        } catch (e) {
            console.log("data", e)
        }
    }

    myFunction();
    return (

        <>
            {owner ?  <>
            <div id="loader">
                <img src={loader} />
            </div>

            <div style={{ display: "none" }} id="myDiv" class="animate-bottom">

                <Header />

                <section className="section w-100">
                    <Container>
                        
                        <div className="admin-page">
                        {/* <div className="head-btn">
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">SetNftaddress</button>
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Start Auction</button>
                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3">Bid</button>
                        </div> */}
                            <Row >
                                <Col lg={4}>
                                    <h3 className="admin-title">Search Here</h3>
                                    <input className="form-control" placeholder="Enter ID to get NFT" value={token_ID} onChange={(e) => setToken_ID(e.target.value)}/>
                                    
                                    <div className="admin-btn-group">
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3" onClick={loadOneNFT1}>Fallen One</button>
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3" onClick={loadOneNFT2}>Guardian One</button>
                                    </div>

                                </Col>
                                <Col lg={4}>
                                    <h3 className="admin-title">Result</h3>
                                    <div>
                <p>owner : {nft_1 !== undefined ? nft_1.owner : "null"}</p>
                <p>Token ID : {nft_1 !== undefined ? nft_1.id : "null"}</p>
                <img src={nft_1 !== undefined ? nft_1.data.image : null} />
                
            
            </div>
            <div>
                <p>owner : {nft_2 !== undefined ? nft_2.owner : "null"}</p>
                <p>Token ID : {nft_2 !== undefined ? nft_2.id : "null"}</p> 
                <img src={nft_2 !== undefined ? nft_2.data.image : null} />
                
            
            </div>
                                </Col>
                            </Row>

                        </div>
                        <div className="admin-page">

                            <Row >
                                <Col lg={4}>
                                    <h3 className="admin-title">Search Here</h3>
                                    <input className="form-control" placeholder="Enter address to get NFT" value={user_address} onChange={(e) => setUser_address(e.target.value)}/>
                                    
                                    
                                    <div className="admin-btn-group">
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3" onClick={() => {
                                        loadNFTs()
                                      //  setNft_2("")
                                        }}>Fallen One</button>
                                        <button className="btn btn-primary btn-wallet p-2 mb-0 mb-md-3" onClick={() => {
                    loadNFTs2()
                    //setNft_1("")
                }}>Guardian One</button>
                                    </div>
                                   

                                </Col>
                                <Col lg={4}>
                                    <h3 className="admin-title">Result</h3>

                                    <div>
            {nft1 !== undefined && nft1 !== null ? nft1.map((arr, i) => {
                 console.log("arr>>>", arr.id)
                return(
                    <div key={i}>
                        {console.log("arr>>>", arr.data.name)}
                        <p>Token ID : { arr.id }</p>
                       <p>name : { arr.data.name }</p>
                       <img src={arr.data.image} />
                       <p>description : {arr.data.description}</p>
                    </div>
                )
               
                
            }) : "null"}
            </div>
            <div>
            {nft2 !== undefined && nft2 !== null ? nft2.map((arr, i) => {
                 console.log("arr>>>", arr.id)
                return(
                    <div key={i}>
                        {console.log("arr>>>", arr.data.name)}
                        <p>Token ID : { arr.id }</p>
                       <p>name : { arr.data.name }</p>
                       <img src={arr.data.image} />
                       <p>description : {arr.data.description}</p>
                    </div>
                )
               
                
            }) : "null"}
            </div>

                                </Col>
                            </Row>

                        </div>
                    </Container>
                </section>

                {/* <div id="footer-section" className="position-relative">
                    <Footer />
                </div> */}

            </div>
            </>:null}
        </>

    )
}

export default Admin;