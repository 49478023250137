import bgMerged from "../assets/images/road-map-tree1-merged.png";

import logoGuardian from "../assets/images/logo-guardian-1.png";
import logoFallen from "../assets/images/logo-fallen-1.png";
import logoPixel from "../assets/images/logo-pixel-1.png";
import { Container } from "react-bootstrap";
import DrawingConcept from "./DrawingConcept";
import DownArrow from "./DownArrow";

const RoadmapDesktop = () => {
  return (
    <section className="roadmap-section d-none d-lg-block pt-lg-5 ">
      <div className="roadmap position-relative py-5">
        <h1 className="text-center mb-5 section-title">ROADMAP</h1>

        <div className="grid-parent">
          <div className="grid-child-curve">
            <img src={bgMerged} className="" />
          </div>

          <div className="grid-child-text">
            <div className="grid-parent-roadmap-parts mx-2">
              <div className="grid-child-roadmap-part-1 d-flex align-items-center gap-2">
                <h2>1</h2>
                <div>
                  <h3>GUARDIAN ANGELS</h3>
                  <p>
                    <p className="mx-5">
                      <p>
                        Not wicked, nor temptatious <br />
                        Once perceived, their charm gets contagious
                      </p>{" "}
                      <p className="mt-3">
                        With a heart and soul, just like you and me <br />
                        You will feel their presence, this is a guarantee
                      </p>{" "}
                      <p className="mt-3">
                        Every being is extraordinary, this is oh so true
                        <br />
                        So thank your Guardian angels, because they have chosens
                        you!
                      </p>{" "}
                    </p>
                    <p className="mt-3">
                      Guardian Angels is the first collection by Celestial
                      Angels NFT. It consists of 2,450 unique angels with over
                      13 completely randomized traits that depict an ethereal
                      aura. In this collection, there are only 7 legendary 1/1
                      Angels, a number that symbolizes their completion and
                      perfection!
                    </p>
                  </p>
                </div>
              </div>

              <div
                className="grid-child-roadmap-part-2 d-flex align-items-center"
                // style={{ marginBottom: "12rem" }}
              >
                <h2>2</h2>
                <div>
                  <h3 className="golden">FALLEN ANGELS</h3>
                  <p>
                    <p className="mx-5">
                      <p>
                        Guarding and protecting?, that is not their style
                        <br />
                        They don't seem to be kind hearted, or virtuous like
                        Gabriel and Mika’il
                      </p>{" "}
                      <p className="mt-3">
                        They were thrown out of heaven, you see.. they have no
                        soul
                        <br />
                        Their intention is to bring corruption, and spread it
                        like a mole
                      </p>{" "}
                      <p className="mt-3">
                        They go by many names, some call them Jinn
                        <br />
                        Pure evil regardless, because the fallen incite you to
                        sin!
                      </p>{" "}
                    </p>
                    <p className="mt-3">
                      Fallen Angels is the second collection by Celestial Angels
                      NFT. It consists of 2,450 unique angels with over 13
                      completely randomized traits that depicts the eternal
                      abyss of the netherworld. In this collection, there are
                      only 7 legendary 1/1 Angels, a number that illustrates the
                      7 deadly sins!
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid-child-text grid-child-roadmap-part-3 d-flex align-items-center">
            <h2>3</h2>
            <div>
              <h3 className="golden">PHOTO MOSAIC</h3>
              <p className="mx-2">
                <p className="mx-lg-5">
                  {" "}
                  This is the third and final collection by Celestial Angels.
                  The name, design and meaning behind the final photo mosaic
                  artwork will be drip fed throughout the process of the first
                  two collections. <br /> Note: THERE WILL BE ONLY 1 ITEM IN
                  THIS COLLECTION FOR THE AUCTION!{" "}
                </p>
                <br /> The proceeds from the auction of the final photo mosaic
                artwork are to be distributed evenly to holders with a minimum
                of 1 full set of Angels (1 Guardian Angel + 1 Fallen Angel){" "}
                <strong> 1 full set = 1 share from total proceeds </strong> If a
                wallet holds multiple sets; they are entitled to multiple shares
                from the photo mosaic earnings accordingly. <br /> I.e. A wallet
                with 5 Guardian Angels and 6 Fallen Angels is entitled to 5
                shares ONLY, as the sixth set is not complete. <br /> Full set
                holders will have a set timeframe to submit their wallets prior
                to auction announcement. Any wallet submission received after
                the set deadline will be disregarded. It is worth mentioning in
                this regard; fewer shares leads to more earnings per set. The
                formula used for this distribution relies on the number of
                wallet submissions along with the consideration of factors such
                as gas fees for all fund transfers. Once determined, the total
                estimated gas fees will be deducted from total earnings; while
                the remaining funds get divided into the corresponding number of
                shares for distribution. <br /> Additional benefits: <br />
                <p className="mx-lg-3">
                  - Full set holders will also receive airdrops of
                  Whitelist-pass NFTs for upcoming projects at a later stage.
                  Remember this is just the beginning!
                </p>{" "}
                <p className="mx-lg-3">
                  {" "}
                  - All holders will also be invited to IRL events in well-known
                  cities around the world.
                </p>
              </p>
            </div>
          </div>
        </div>
        <DownArrow to="drawing" />
      </div>

      <div
        id="drawing"
        className="position-relative"
        style={{
          paddingBottom: "5rem",
        }}
      >
        <DrawingConcept />
        <DownArrow to="team-section" />
      </div>
    </section>
  );
};

const RoadmapMobile = () => {
  return (
    <div className="roadmap-section-mobile d-lg-none pt-5">
      <Container>
        <h1 className="text-center section-title mb-2">ROADMAP</h1>
        <div className="logo-guardian mb-5">
          <img src={logoGuardian} />
          <div>
            <div className="d-flex align-items-baseline gap-3">
              <h1>1</h1>
              <h3>GUARDIAN ANGELS</h3>
            </div>
            <p>
              <p className="mx-sm-5 mx-2 ">
                <p>
                  Not wicked, nor temptatious <br />
                  Once perceived, their charm gets contagious
                </p>{" "}
                <p className="mt-3">
                  With a heart and soul, just like you and me <br />
                  You will feel their presence, this is a guarantee
                </p>{" "}
                <p className="mt-3">
                  Every being is extraordinary, this is oh so true
                  <br />
                  So thank your Guardian angels, because they have chosens you!
                </p>{" "}
              </p>
              <p className="mt-3">
                Guardian Angels is the first collection by Celestial Angels NFT.
                It consists of 2,450 unique angels with over 13 completely
                randomized traits that depict an ethereal aura. In this
                collection, there are only 7 legendary 1/1 Angels, a number that
                symbolizes their completion and perfection!
              </p>
            </p>
          </div>
        </div>

        <div className="logo-fallen mb-5">
          <img src={logoFallen} />
          <div>
            <div className="d-flex align-items-baseline justify-content-lg-end gap-3">
              <h1>2</h1>
              <h3 className="golden">FALLEN ANGELS</h3>
            </div>
            <p>
              <p className="mx-sm-5 mx-2 ">
                <p>
                  Guarding and protecting?, that is not their style
                  <br />
                  They don't seem to be kind hearted, or virtuous like Gabriel
                  and Mika’il
                </p>{" "}
                <p className="mt-3">
                  They were thrown out of heaven, you see.. they have no soul
                  <br />
                  Their intention is to bring corruption, and spread it like a
                  mole
                </p>{" "}
                <p className="mt-3">
                  They go by many names, some call them Jinn
                  <br />
                  Pure evil regardless, because the fallen incite you to sin!
                </p>{" "}
              </p>
              <p className="mt-3">
                Fallen Angels is the second collection by Celestial Angels NFT.
                It consists of 2,450 unique angels with over 13 completely
                randomized traits that depicts the eternal abyss of the
                netherworld. In this collection, there are only 7 legendary 1/1
                Angels, a number that illustrates the 7 deadly sins!
              </p>
            </p>
          </div>
        </div>

        <div className="logo-pixel mb-5 position-relative">
          <img src={logoPixel} />
          <div>
            <div className="d-flex align-items-baseline gap-3">
              <h1>3</h1>
              <h3 className="golden">PHOTO MOSAIC</h3>
            </div>
            <p>
              <p className="mx-sm-5 mx-2 ">
                {" "}
                This is the third and final collection by Celestial Angels. The
                name, design and meaning behind the final photo mosaic artwork
                will be drip fed throughout the process of the first two
                collections. <br /> Note: THERE WILL BE ONLY 1 ITEM IN THIS
                COLLECTION FOR THE AUCTION!{" "}
              </p>
              <br /> The proceeds from the auction of the final photo mosaic
              artwork are to be distributed evenly to holders with a minimum of
              1 full set of Angels (1 Guardian Angel + 1 Fallen Angel){" "}
              <strong> 1 full set = 1 share from total proceeds </strong> If a
              wallet holds multiple sets; they are entitled to multiple shares
              from the photo mosaic earnings accordingly. <br /> I.e. A wallet
              with 5 Guardian Angels and 6 Fallen Angels is entitled to 5 shares
              ONLY, as the sixth set is not complete. <br /> Full set holders
              will have a set timeframe to submit their wallets prior to auction
              announcement. Any wallet submission received after the set
              deadline will be disregarded. It is worth mentioning in this
              regard; fewer shares leads to more earnings per set. The formula
              used for this distribution relies on the number of wallet
              submissions along with the consideration of factors such as gas
              fees for all fund transfers. Once determined, the total estimated
              gas fees will be deducted from total earnings; while the remaining
              funds get divided into the corresponding number of shares for
              distribution. <br /> Additional benefits: <br />
              <p className="mx-lg-3">
                - Full set holders will also receive airdrops of Whitelist-pass
                NFTs for upcoming projects at a later stage. Remember this is
                just the beginning!
              </p>{" "}
              <p className="mx-lg-3">
                {" "}
                - All holders will also be invited to IRL events in well-known
                cities around the world.
              </p>
            </p>
          </div>
          <DownArrow to="drawing-concept-section" />
        </div>
      </Container>
      <div
        id="drawing-concept-section"
        className="position-relative"
        style={{
          paddingBottom: "5rem",
        }}
      >
        {" "}
        <DrawingConcept mobile={true} />
        <DownArrow to="team-section" />
      </div>
    </div>
  );
};

const Roadmap = () => {
  return (
    <>
      <RoadmapMobile />
      <RoadmapDesktop />
    </>
  );
};

export default Roadmap;
